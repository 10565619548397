import React, { useState, useEffect, useRef } from 'react';
import { format, differenceInSeconds, addMinutes, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableCaption,
} from "../components/ui/table";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";

interface Task {
  _id: string;
  startTime: string;
  endTime: string;
  description: string;
}

interface EditingTask extends Task {
  timeSpentMinutes: number;
}

interface TaskTableProps {
  tasks: Task[];
  setTasks: React.Dispatch<React.SetStateAction<Task[]>>;
  fetchTasks: () => Promise<void>;
}

const TaskTable: React.FC<TaskTableProps> = ({ tasks, setTasks, fetchTasks }) => {
  const [editingTask, setEditingTask] = useState<EditingTask | null>(null);
  const [newTaskDescription, setNewTaskDescription] = useState('');
  const [newTaskDuration, setNewTaskDuration] = useState('');
  const [currentTime, setCurrentTime] = useState(new Date());
  const descriptionInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    descriptionInputRef.current?.focus();
  }, []);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

  const toLocalISOString = (date: Date) => {
    const offset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - offset).toISOString().slice(0, 16);
  };

  const fromLocalISOString = (localISOString: string) => {
    const date = new Date(localISOString);
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000).toISOString();
  };

  const handleEdit = (task: Task) => {
    const timeSpentMinutes = Math.round(differenceInSeconds(new Date(task.endTime), new Date(task.startTime)) / 60);
    setEditingTask({ ...task, timeSpentMinutes });
  };

  const updateEditingTaskTime = (newTimeSpentMinutes: number, newEndTime: Date) => {
    if (editingTask) {
      const startTime = new Date(editingTask.startTime);
      const updatedEndTime = addMinutes(startTime, newTimeSpentMinutes);
      
      setEditingTask({
        ...editingTask,
        timeSpentMinutes: newTimeSpentMinutes,
        endTime: updatedEndTime.toISOString(),
      });
    }
  };

  const handleSave = async () => {
    if (editingTask) {
      const endTime = new Date(editingTask.startTime);
      endTime.setMinutes(endTime.getMinutes() + editingTask.timeSpentMinutes);

      const updatedTask: Task = {
        _id: editingTask._id,
        startTime: editingTask.startTime,
        endTime: endTime.toISOString(),
        description: editingTask.description,
      };

      try {
        const response = await fetch(`${API_BASE_URL}/user/update`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include', // Include cookies
          body: JSON.stringify([updatedTask]),
        });
        if (!response.ok) {
          const errorData = await response.json();
          toast.error(errorData.message || 'Failed to update task. Please try again.');
          return;
        }
        const data = await response.json();
        if (data.ok) {
          setTasks(tasks.map(t => t._id === editingTask._id ? updatedTask : t));
          setEditingTask(null);
          // Set focus back to the description input after saving
          descriptionInputRef.current?.focus();
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.error('Failed to update task:', error);
        toast.error('Failed to update task. Please try again.');
      }
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSave();
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await fetch(`${API_BASE_URL}/user/task/${id}`, {
        method: 'DELETE',
        credentials: 'include', // Include cookies
      });
      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.message || 'Failed to delete task. Please try again.');
        return;
      }
      const data = await response.json();
      if (data.ok) {
        setTasks(tasks.filter(t => t._id !== id));
        // Set focus back to the description input after deleting
        descriptionInputRef.current?.focus();
      }
    } catch (error) {
      console.error('Failed to delete task:', error);
      toast.error('Failed to delete task. Please try again.');
    }
  };

  const handleTaskTap = (description: string) => {
    setNewTaskDescription(description);
    descriptionInputRef.current?.focus();
  };

  const handleAddTask = async (e?: React.FormEvent) => {
    if (e) e.preventDefault();
    const lastTask = tasks[tasks.length - 1];
    const startTime = lastTask ? new Date(lastTask.endTime) : new Date();
    let endTime = new Date(); // Use current time as end time

    if (lastTask && newTaskDescription === lastTask.description) {
      // Update the last task instead of adding a new one
      const updatedTask = {
        ...lastTask,
        endTime: endTime.toISOString(),
      };

      try {
        const response = await fetch(`${API_BASE_URL}/user/update`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify([updatedTask]),
        });
        if (!response.ok) {
          const errorData = await response.json();
          toast.error(errorData.message || 'Failed to update task. Please try again.');
          return;
        }
        const data = await response.json();
        if (data.ok) {
          setTasks(tasks.map(t => t._id === lastTask._id ? updatedTask : t));
          setNewTaskDescription('');
          setNewTaskDuration('');
          descriptionInputRef.current?.focus();
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.error('Failed to update task:', error);
        toast.error('Failed to update task. Please try again.');
      }
    } else {
      // Add a new task (existing code)
      let newTaskEndTime: Date;
      if (newTaskDuration) {
        newTaskEndTime = new Date(startTime.getTime() + parseInt(newTaskDuration) * 60000);
      } else {
        newTaskEndTime = endTime;
      }

      const newTask = {
        description: newTaskDescription,
        startTime: startTime.toISOString(),
        endTime: newTaskEndTime.toISOString(),
      };

      try {
        const response = await fetch(`${API_BASE_URL}/user/task`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include', // Include cookies
          body: JSON.stringify(newTask),
        });
        if (!response.ok) {
          const errorData = await response.json();
          toast.error(errorData.message || 'Failed to add task. Please try again.');
          return;
        }
        const data = await response.json();
        if (data.ok) {
          setTasks([...tasks, data.task]);
          setNewTaskDescription('');
          setNewTaskDuration('');
          // Set focus back to the description input
          descriptionInputRef.current?.focus();
        }
      } catch (error) {
        console.error('Failed to add task:', error);
        toast.error('Failed to add task. Please try again.');
      }
    }
  };

  const formatTime = (isoString: string) => {
    const date = new Date(isoString);
    return format(date, 'h:mm a');
  };

  const formatTimeSpent = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.round(minutes % 60);
    
    if (hours > 0) {
      return `${hours}h ${remainingMinutes}m`;
    } else {
      return `${remainingMinutes}m`;
    }
  };

  const calculateTimeSpent = (startTime: string, endTime: string): string => {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const diffMinutes = Math.round((end.getTime() - start.getTime()) / 60000);
    return formatTimeSpent(diffMinutes);
  };

  // Add this sorting function
  const sortedTasks = [...tasks].sort((a, b) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime());

  return (
    <div>
      <form onSubmit={handleAddTask} className="flex gap-2 mb-4 p-4 border-t border-b border-gray-200">
        <Input
          type="text"
          value={newTaskDescription}
          onChange={(e) => setNewTaskDescription(e.target.value)}
          placeholder="New task description"
          className="flex-grow"
          ref={descriptionInputRef}
        />
        <Input
          type="number"
          value={newTaskDuration}
          onChange={(e) => setNewTaskDuration(e.target.value)}
          placeholder="Minutes"
          className="w-24"
        />
        <Button type="submit" className="w-44">Add</Button>
      </form>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[100px]">Start</TableHead>
            <TableHead className="w-[300px]">Description</TableHead>
            <TableHead className="w-[150px]">Time Spent</TableHead>
            <TableHead className="w-[150px]">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {sortedTasks.map((task, index) => (
            <TableRow key={task._id}>
              <TableCell className="font-medium text-left">
                {editingTask?._id === task._id ? (
                  <Input
                    type="time"
                    value={format(new Date(editingTask.startTime), 'HH:mm')}
                    onChange={e => {
                      const [hours, minutes] = e.target.value.split(':');
                      const newDate = new Date(editingTask.startTime);
                      newDate.setHours(parseInt(hours), parseInt(minutes));
                      setEditingTask({...editingTask, startTime: newDate.toISOString()});
                    }}
                    onKeyPress={handleKeyPress}
                  />
                ) : formatTime(task.startTime)}
              </TableCell>
              <TableCell className="max-w-[300px] truncate text-left">
                {editingTask?._id === task._id ? (
                  <Input
                    value={editingTask.description}
                    onChange={e => setEditingTask({...editingTask, description: e.target.value})}
                    onKeyPress={handleKeyPress}
                  />
                ) : (
                  <span 
                    className="cursor-pointer hover:underline"
                    onClick={() => handleTaskTap(task.description)}
                  >
                    {task.description}
                  </span>
                )}
              </TableCell>
              <TableCell className="text-left">
                {editingTask?._id === task._id ? (
                  <div className="flex flex-col space-y-2">
                    <div className="flex items-center space-x-2">
                      <Input
                        type="number"
                        min="1"
                        value={editingTask.timeSpentMinutes}
                        onChange={e => {
                          const newTimeSpentMinutes = parseInt(e.target.value) || 0;
                          updateEditingTaskTime(newTimeSpentMinutes, new Date(editingTask.endTime));
                        }}
                        onKeyPress={handleKeyPress}
                        className="w-20"
                      />
                      <span>minutes</span>
                    </div>
                    <Input
                      type="time"
                      value={format(new Date(editingTask.endTime), 'HH:mm')}
                      onChange={e => {
                        const [hours, minutes] = e.target.value.split(':');
                        const newEndTime = new Date(editingTask.endTime);
                        newEndTime.setHours(parseInt(hours), parseInt(minutes));
                        const newTimeSpentMinutes = Math.round(differenceInSeconds(newEndTime, new Date(editingTask.startTime)) / 60);
                        updateEditingTaskTime(newTimeSpentMinutes, newEndTime);
                      }}
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                ) : (
                  calculateTimeSpent(task.startTime, task.endTime)
                )}
              </TableCell>
              <TableCell className="text-left">
                {editingTask?._id === task._id ? (
                  <>
                    <Button variant="link" onClick={handleSave}>Save</Button>
                    {index === 0 && (
                      <Button variant="link" className="text-red-500 hover:text-red-700" onClick={() => handleDelete(task._id)}>Delete</Button>
                    )}
                  </>
                ) : (
                  <>
                    <Button variant="link" onClick={() => handleEdit(task)}>Edit</Button>
                    {index === 0 && (
                      <Button variant="link" className="text-red-500 hover:text-red-700" onClick={() => handleDelete(task._id)}>Delete</Button>
                    )}
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default TaskTable;