import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import TaskTable from './TaskTable';
import TimeBar from './TimeBar';  

interface Task {
  _id: string;
  startTime: string;
  endTime: string;
  description: string;
}

const Home: React.FC = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

  const fetchTasks = async () => {
    try {
      const thirtySixHoursAgo = new Date(Date.now() - 36 * 60 * 60 * 1000).toISOString();
      const response = await fetch(`${API_BASE_URL}/user/getTasks?since=${thirtySixHoursAgo}`, {
        method: 'GET',
        credentials: 'include',
      });
      if (response.status === 403) {
        // Trigger logout
        window.dispatchEvent(new Event('logout'));
        return;
      }
      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.message || 'Failed to fetch tasks. Please try again.');
        return;
      }
      const data = await response.json();
      setTasks(data.tasks);
    } catch (error) {
      console.error('Failed to fetch tasks:', error);
      toast.error('Failed to fetch tasks. Please try again.');
    }
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  return (
    <div>
      <TimeBar tasks={tasks} />
      <TaskTable
        tasks={tasks}
        setTasks={setTasks}
        fetchTasks={fetchTasks}
      />
    </div>
  );
};

export default Home;