import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import Login from './components/Login';
import Signup from './components/Signup'; // New import
import History from './components/History';
import * as ReactToastify from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const storedLoginState = localStorage.getItem('isLoggedIn');
    if (storedLoginState === 'true') {
      setIsLoggedIn(true);
      ReactToastify.toast.success('Welcome back!');
    }

    // Add event listener for logout
    const handleLogoutEvent = () => {
      handleLogout();
    };
    window.addEventListener('logout', handleLogoutEvent);

    // Clean up the event listener
    return () => {
      window.removeEventListener('logout', handleLogoutEvent);
    };
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
    localStorage.setItem('isLoggedIn', 'true');
    ReactToastify.toast.success('Logged in successfully!');
  };

  const handleLogout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/logout`, {
        method: 'POST',
        credentials: 'include',
      });
      
      if (response.ok) {
        ReactToastify.toast.info('Logged out successfully');
      } else {
        throw new Error('Logout failed');
      }
    } catch (error) {
      console.error('Logout error:', error);
      ReactToastify.toast.error('Logout failed on the server. You have been logged out locally.');
    } finally {
      // Clear local state and storage regardless of server response
      setIsLoggedIn(false);
      localStorage.removeItem('isLoggedIn');
    }
  };

  const handleSignup = () => {
    setIsLoggedIn(true);
    localStorage.setItem('isLoggedIn', 'true');
    ReactToastify.toast.success('Signed up and logged in successfully!');
  };

  return (
    <Router>
      <div className="App">
        <ReactToastify.ToastContainer position="bottom-right" autoClose={3000} />
        <Header 
          isLoggedIn={isLoggedIn} 
          onLogout={handleLogout}
        />
        <Routes>
          <Route path="/login" element={!isLoggedIn ? <Login onLogin={handleLogin} /> : <Navigate to="/" />} />
          <Route path="/signup" element={!isLoggedIn ? <Signup onSignup={handleSignup} /> : <Navigate to="/" />} />
          <Route path="/history" element={isLoggedIn ? <History /> : <Navigate to="/login" />} />
          <Route path="/" element={isLoggedIn ? <Home /> : <Navigate to="/login" />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;