import React, { useState, useEffect } from 'react';
import { format, differenceInSeconds } from 'date-fns';
import styles from './Header.module.css';

interface TimeBarProps {
  tasks: Array<{ endTime: string }>;
}

const TimeBar: React.FC<TimeBarProps> = ({ tasks }) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const getLatestTaskEndTime = (): Date => {
    if (tasks.length === 0) return new Date();
    return new Date(tasks[tasks.length - 1].endTime);
  };

  const calculateTimerValue = (): string => {
    const latestEndTime = getLatestTaskEndTime();
    const diffSeconds = differenceInSeconds(currentTime, latestEndTime);
    return formatElapsedTime(diffSeconds);
  };

  const formatDateTime = (date: Date) => {
    return format(date, "EEEE, d MMMM - h:mm a");
  };

  const formatElapsedTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    
    let result = '';
    if (hours > 0) result += `${hours}h `;
    if (minutes > 0 || hours > 0) result += `${minutes}m `;
    result += `${remainingSeconds}s`;
    
    return result.trim();
  };

  return (
    <div className={styles.bottomSection}>
      <div className={`${styles.dateTime} ${styles.largeFont}`}>{formatDateTime(currentTime)}</div>
      <div className={`${styles.timeElapsed} ${styles.largeFont}`}>
        {calculateTimerValue()}
      </div>
    </div>
  );
};

export default TimeBar;