import React from 'react';
import styles from './Header.module.css';
import { Button } from "../components/ui/button"
import { useNavigate, useLocation } from 'react-router-dom';

interface HeaderProps {
  isLoggedIn: boolean;
  onLogout: () => void;
}

const Header: React.FC<HeaderProps> = ({ isLoggedIn, onLogout }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleHistoryClick = () => {
    navigate('/history');
  };

  const handleHomeClick = () => {
    navigate('/');
  };

  const isHistoryPage = location.pathname === '/history';

  return (
    <header className={styles.header}>
      <div className={styles.topSection}>
        <div className={styles.logo}>
          <img src="/time.svg" alt="TapTime logo" />
          <span>TapTime</span>
        </div>
        <div className={styles.buttons}>
          {isLoggedIn && (
            <>
              {isHistoryPage ? (
                <Button variant="outline" onClick={handleHomeClick}>Home</Button>
              ) : (
                <Button variant="outline" onClick={handleHistoryClick}>History</Button>
              )}
              <Button onClick={onLogout} variant="outline">Logout</Button>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;