import React, { useState, useEffect } from 'react';
import { format, differenceInSeconds } from 'date-fns';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../components/ui/table";
import { BarChart, Card as TremorCard } from "@tremor/react";
import { toast } from 'react-toastify';
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";

interface Task {
  _id: string;
  startTime: string;
  endTime: string;
  description: string;
}

const History: React.FC = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<{ description: string, totalTime: number } | null>(null);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

  useEffect(() => {
    const fetchHistoricalData = async () => {
      try {
        setIsLoading(true);
        const epochStart = new Date(0).toISOString();
        const response = await fetch(`${API_BASE_URL}/user/getTasks?since=${epochStart}`, {
          method: 'GET',
          credentials: 'include',
        });
        if (response.status === 403) {
          window.dispatchEvent(new Event('logout'));
          return;
        }
        if (!response.ok) {
          const errorData = await response.json();
          toast.error(errorData.message || 'Failed to fetch task history');
          setError(errorData.message || 'Failed to fetch task history');
          return;
        }
        const data = await response.json();
        setTasks(data.tasks);
      } catch (err) {
        setError('Failed to fetch task history');
        console.error(err);
        toast.error('Failed to fetch task history');
      } finally {
        setIsLoading(false);
      }
    };

    fetchHistoricalData();
  }, [API_BASE_URL]);

  const formatTime = (isoString: string) => {
    return format(new Date(isoString), 'h:mm a');
  };

  const formatTimeSpent = (minutes: number): string => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    
    if (hours > 0) {
      return `${hours} h ${remainingMinutes}m`;
    } else {
      return `${remainingMinutes}m`;
    }
  };

  const calculateTimeSpent = (startTime: string, endTime: string): string => {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const diffSeconds = Math.floor((end.getTime() - start.getTime()) / 1000);
    return formatTimeSpent(diffSeconds / 60);
  };

  const getTop10Tasks = () => {
    const taskMap = new Map<string, number>();
    
    tasks.forEach(task => {
      const duration = differenceInSeconds(new Date(task.endTime), new Date(task.startTime)) / 60;
      const currentTotal = taskMap.get(task.description) || 0;
      taskMap.set(task.description, currentTotal + duration);
    });

    const sortedTasks = Array.from(taskMap.entries())
      .sort((a, b) => b[1] - a[1])
      .slice(0, 10)
      .map(([name, duration]) => ({
        name,
        "Duration (minutes)": Math.round(duration)
      }));

    return sortedTasks;
  };

  const chartData = getTop10Tasks();

  const searchAndAnalyzeTasks = () => {
    if (!searchTerm.trim()) {
      setSearchResults(null);
      return;
    }

    const matchingTasks = tasks.filter(task => 
      task.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (matchingTasks.length === 0) {
      setSearchResults({ description: searchTerm, totalTime: 0 });
      return;
    }

    const totalTimeInSeconds = matchingTasks.reduce((total, task) => {
      return total + differenceInSeconds(new Date(task.endTime), new Date(task.startTime));
    }, 0);

    setSearchResults({
      description: searchTerm,
      totalTime: totalTimeInSeconds / 60, // Convert to minutes
    });
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6">Task History</h1>
      
      <Card className="mb-8">
        <CardHeader>
          <CardTitle>Task Analysis</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex space-x-2 mb-4">
            <Input
              type="text"
              placeholder="Search tasks..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button onClick={searchAndAnalyzeTasks}>Analyze</Button>
          </div>
          {searchResults && (
            <p>
              Total time spent on tasks containing "{searchResults.description}": {formatTimeSpent(searchResults.totalTime)}
            </p>
          )}
        </CardContent>
      </Card>

      <Card className="mb-8">
        <CardHeader>
          <CardTitle>Top 10 Time-Consuming Tasks</CardTitle>
        </CardHeader>
        <CardContent>
          <TremorCard>
            <BarChart
              className="h-72"
              data={chartData}
              index="name"
              categories={["Duration (minutes)"]}
              colors={["blue"]}
              yAxisWidth={48}
            />
          </TremorCard>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Task Details</CardTitle>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[100px]">Start</TableHead>
                <TableHead className="w-[300px]">Description</TableHead>
                <TableHead className="w-[150px]">Time Spent</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {tasks.map(task => (
                <TableRow key={task._id}>
                  <TableCell className="font-medium text-left">{formatTime(task.startTime)}</TableCell>
                  <TableCell className="max-w-[300px] truncate text-left">{task.description}</TableCell>
                  <TableCell className="text-left">{calculateTimeSpent(task.startTime, task.endTime)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};

export default History;